import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const tokens = (mode) => ({
      customTheme: {
        primary: "#452f87", 
        darkPrimary: "#301f5f",   
        background: "#eee", 
        textColor: "#fff",
        whiteTextColor: "#fff",
        darkTextColor: "#000"
      },  
    });


export const themeSettings = () => {
  const colors = tokens(); 
  return {
    palette: {
      primary: {
        main: colors.customTheme.primary,
      },
      background: {
        default: colors.customTheme.background,
      },
    },
   
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const theme = useMemo(() => createTheme(themeSettings()), []);
  return [theme, {}];
};
