import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ValidUserContext } from "../../login/authCheck"; // Ajuste o caminho
import { Formik } from "formik";
import { tokens } from "../../../theme"; // Ajuste o caminho

import * as yup from "yup";

const UserDetails = ({
  onClose,
  showSnackbar,
  userDetails,
  refreshServices,
  setIsEditable,
  isEditable,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);

  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { name, email, roles } = JSON.parse(localStorage.getItem("userInfo"));

  console.log(userDetails);
  // Dialog states
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  // const rolesOptions = ["SU", "Admin", "News"];
  const rolesOptions = userRoles.includes("SU")
    ? ["Super User", "Administrador", "Jornalista"]
    : ["Jornalista"];

  const roleMap = {
    "Super User": "SU",
    Administrador: "ADMIN",
    Jornalista: "NEWS",
  };

  const handleCancelChanges = (resetForm) => {
    resetForm(); // Reset the form
    setIsEditable(false); // Set editable state to false
    setOpenCancelDialog(false); // Close the cancel dialog
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const data = [roleMap[values.roles]];

    console.log(data);
    // console.log(roles);
    // console.log(data);
    const url = `https://projetouniversitas-evora.appspot.com/backoffice/changeRoles/${userDetails.email}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      refreshServices();
      showSnackbar("Permissões alteradas com sucesso!"); // Adicione esta linha
      const responseData = await response.text();
      // console.log("Success:", responseData);
    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose();
  };

  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true
    const url = `https://projetouniversitas-evora.appspot.com/backoffice/delete/${userDetails.email}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to delete the contact.");
      }
      refreshServices();
      showSnackbar("Utilizador eliminado com sucesso!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const canEditUser =
    userRoles.includes("SU") ||
    (userRoles.includes("ADMIN") && userDetails.access === "Jornalista");

  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={{
          firstName: userDetails.name || "",
          email: userDetails.email || "", // Initialize email
          roles: [userDetails.access] || "",
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          submitForm,
          resetForm,
          setFieldValue,
          handleSubmit,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Box display="flex" gap="20px" flexDirection="column">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={!!touched.firstName && !!errors.firstName}
                  //helperText={touched.firstName && errors.firstName}
                  sx={{ border: "1px solid #000", borderRadius: "4px" }}
                  InputProps={{
                    style: { color: "#000" }, // Cor do texto de input para preto
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    style: { color: "#000" }, // Cor do label para preto
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  // helperText={touched.email && errors.email}
                  sx={{ border: "1px solid #000", borderRadius: "4px" }}
                  InputProps={{
                    style: { color: "#000" }, // Cor do texto de input para preto
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    style: { color: "#000" }, // Cor do label para preto
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Permissões"
                  value={userDetails.access || ""}
                  InputProps={{
                    style: { color: "#000" }, // Cor do texto de input para preto
                    readOnly: true,
                  }}
                />

                {isEditable && (
                  <FormControl fullWidth variant="filled">
                    <InputLabel
                      id="roles-label"
                      sx={{
                        color: "#000", // Cor do label para preto
                        "&.Mui-focused": {
                          color: "#000", // Cor do label quando focado
                        },
                      }}
                    >
                      Permissões
                    </InputLabel>
                    <Select
                      labelId="roles-label"
                      id="roles"
                      value={values.roles}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="roles"
                      error={!!touched.roles && !!errors.roles}
                      readOnly={!isEditable}
                      //helperText={touched.roles && errors.roles}
                      renderValue={(selected) => selected}
                      sx={{
                        color: "#000", // Cor do texto selecionado para preto
                        backgroundColor: "transparent", // Fundo transparente
                        border: "1px solid #000", // Borda preta
                        borderRadius: "4px", // Borda arredondada
                        "& .MuiSelect-icon": {
                          color: "#000", // Cor do ícone de seta para preto
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: "white", // Fundo branco das opções do menu
                            color: "#000", // Cor do texto das opções
                          },
                        },
                      }}
                    >
                      {rolesOptions.map((role) => (
                        <MenuItem
                          key={role}
                          value={role}
                          sx={{
                            color: "#000", // Cor do texto das opções do menu
                          }}
                        >
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {canEditUser && (
                  <Box display="flex" justifyContent="space-between" mt="20px">
                    {!isEditable && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpenDeleteDialog(true)}
                        disabled={isDeleting}
                        startIcon={
                          isDeleting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                      >
                        {isDeleting ? "A eliminar..." : "Eliminar Utilizador"}
                      </Button>
                    )}

                    {isEditable && (
                      <Button
                        onClick={() => setOpenCancelDialog(true)}
                        color="error"
                      >
                        Cancelar Alterações
                      </Button>
                    )}

                    <Button
                      onClick={() => {
                        if (isEditable) {
                          setOpenSaveDialog(true);
                        } else {
                          setIsEditable(true);
                        }
                      }}
                      sx={{
                        backgroundColor: colors.customTheme.primary,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: colors.customTheme.darkPrimary,
                        },
                      }}
                      variant="contained"
                      disabled={isSubmitting}
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isSubmitting
                        ? "A guardar..."
                        : isEditable
                        ? "Guardar Alterações"
                        : "Editar"}
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
            {/* Dialog for save confirmation */}
            <Dialog
              open={openSaveDialog}
              onClose={() => setOpenSaveDialog(false)}
            >
              <DialogTitle>Confirmar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja guardar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenSaveDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    submitForm(); // Call submitForm from Formik
                    setOpenSaveDialog(false); // Fecha o diálogo após salvar
                  }}
                  color="primary"
                >
                  Sim, Guardar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for delete confirmation */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
            >
              <DialogTitle>Eliminar Contacto</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja eliminar este serviço? Esta ação não
                  pode ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button onClick={handleDelete} color="error">
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for cancel confirmation */}
            <Dialog
              open={openCancelDialog}
              onClose={() => setOpenCancelDialog(false)}
            >
              <DialogTitle>Cancelar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja cancelar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenCancelDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => handleCancelChanges(resetForm)}
                  color="error"
                >
                  Sim, Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("Campo obrigatório"),
  email: yup.string().email("Email inválido").required("Campo obrigatório"),
  roles: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  firstName: "",
  email: "", // Initialize email
  roles: [], // Initialize roles as an empty array
};

export default UserDetails;
