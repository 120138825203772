import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import Header from "../../../components/Header"; // Ajuste o caminho
import { ValidUserContext } from "../../login/authCheck"; // Ajuste o caminho
import ServiceDetails from "../serviceDetails/index"; // Ajuste o caminho
import CreateService from "../createService/index"; // Ajuste o caminho
import SnackbarComponent from "../../global/Snackbar";
import TableWithPagination from "../../global/Table"; // Usando a tabela personalizada
import Overlay from "../../global/Overlay";

const Services = () => {
  const { userRoles } = useContext(ValidUserContext);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isCreatingService, setIsCreatingService] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [frontendImage, setFrontendImage] = useState("");

  useEffect(() => {}, [isEditable]);

  const fetchServices = async () => {
    const response = await fetch(
      "https://projetouniversitas-evora.appspot.com/services"
    );
    const rawData = await response.json();
    const data = rawData.map((service) => ({
      ...service,
      id: service.ID,
      Tags: JSON.parse(service.Tags),
    }));
    setServices(data);
  };

  useEffect(() => {
    fetchServices();
    fetchFrontendImage();
  }, []);

  const fetchFrontendImage = async () => {
    const response = await fetch(
      "https://projetouniversitas-evora.appspot.com/api/menu-images/services"
    );
    const rawData = await response.json();

    setFrontendImage(rawData.ImageURL);
    console.log(rawData);
    console.log(rawData.ImageURL);
  };

  // Function to upload an image to the backend
  const onImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("name", "services");
    formData.append("photo", file);

    try {
      const response = await fetch(
        "https://projetouniversitas-evora.appspot.com/api/menu-images", // Adjust the endpoint as needed
        {
          method: "PUT",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const data = response.body;
        showSnackbar("Imagem adicionada com sucesso!");
        fetchFrontendImage();
      } else {
        showSnackbar("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showSnackbar("Erro ao adicionar imagem");
    }
  };

  const columns = [
    { label: "ID", field: "ID", hideAlways: true }, // Escondido sempre
    { label: "Nome", field: "Name" },
    { label: "Tipo", field: "Type" },
    {
      label: "Imagem",
      field: "ImageURL",
      isImage: true, // Adicionando o atributo isImage para esta coluna
    },
  ];

  const handleOpenServiceDetails = (service) => {
    setSelectedService(service);
    setIsCreatingService(false);
    setOverlayOpen(true);
  };

  const handleCreateService = () => {
    setSelectedService(null);
    setIsCreatingService(true);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedService(null);
    setIsCreatingService(false);
    setIsEditable(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddServices =
    userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box width="100%" overflow="auto">
      <Header
        title="Serviços"
        subtitle="Listagem de todos os serviços"
        currentImageUrl={frontendImage}
        onImageUpload={onImageUpload}
      />

      <TableWithPagination
        columns={columns}
        rows={services}
        onRowClick={handleOpenServiceDetails}
        onButtonClick={handleCreateService}
        canAddElements={canAddServices}
        buttonTitle={"Serviço"}
      />

      <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingService ? "Criar Serviço" : "Editar Serviço"}
      >
        {selectedService ? (
          <ServiceDetails
            serviceDetails={selectedService}
            onClose={closeOverlay}
            refreshServices={fetchServices}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            showSnackbar={showSnackbar}
          />
        ) : (
          <CreateService
            onClose={closeOverlay}
            refreshServices={fetchServices}
            showSnackbar={showSnackbar}
          />
        )}
      </Overlay>

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Services;
