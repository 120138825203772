import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import Header from "../../components/Header";

const NotificationsPage = () => {

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [screen, setScreen] = useState("");
  const [user, setUser] = useState(""); // New state for the user token
  const [selectedData, setSelectedData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [bannerImage, setBannerImage] = useState(null); // Holds the uploaded file
  const [isUploading, setIsUploading] = useState(false);
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState(null);

  const [frontendImage, setFrontendImage] = useState("");
  const fileInputRef = React.useRef(null);


  // Notification types and corresponding screens
  const notificationTypes = [
    { label: "Geral", value: "/home" },
    { label: "Notícias", value: "/news" },
    { label: "Serviços", value: "/services" },
    { label: "AE Documentos", value: "/ae-documents" },
    { label: "Contactos", value: "/contacts" },
    { label: "Cursos", value: "/notes-bank" },
    { label: "Eventos", value: "/events" },
  ];

  useEffect(() => {
    if (screen === "/news") {
      fetchData("https://projetouniversitas-evora.appspot.com/news", "news");
    } else if (screen === "/ae-documents") {
      fetchData("https://projetouniversitas-evora.appspot.com/ae", "documents");
    } else if (screen === "/events") {
      fetchData("https://api.3cket.com/ext/events", "events", true);
    } else {
      setData([]); // Clear data when changing screen type
    }
  }, [screen]);

  const fetchFrontendImage = async () => {
    const response = await fetch(
      "https://projetouniversitas-evora.appspot.com/api/menu-images/banner"
    );
    const rawData = await response.json();

    setFrontendImage(rawData.ImageURL);
    console.log(rawData);
    console.log(rawData.ImageURL);
  };

  useEffect(() => {
    fetchFrontendImage();
  }, []);

  const fetchData = (url, type, isEvent = false) => {
    if (isFetching) return;

    setIsFetching(true);

    let headers = {};
    if (isEvent) {
      headers = {
        "Authorization": "Bearer 3cket:0eae13a1-54aa-4f80-a3e7-cf9f83cd0e3c",
        "CKT-PROMOTER": "43757a0255c84de4a7b76b3557c8b14a",
      };
    }

    fetch(url, { headers })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then((data) => {
        setData(
          type == "news"
            ? data[type].map((item) => ({ ...item, id: item.ID || item.id }))
            : data.map((item) => ({ ...item, id: item.ID || item.id }))
        );
      })
      .catch((error) => console.error("There was an error!", error))
      .finally(() => {
        setIsFetching(false);
      });
  };

  // Form submission handler
  const handleSubmit = async () => {
    if (!title || !body || !screen) {
      setErrorMessage("Preenche todos os campos antes de enviar.");
      return;
    }

    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      // Modify the screen if a specific news item is selected
      let modifiedScreen = screen;
      if ((screen === "/news" || screen === "/ae-documents" || screen === "/events") && selectedData) {
        modifiedScreen = `${screen}/${selectedData.id}`;
      }

      // API request to send notification
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", modifiedScreen);
      if (user) {
        formData.append("token", user);
      }

      const endpoint = user
        ? "https://projetouniversitas-evora.oa.r.appspot.com/api/notifications/sendToUser"
        : "https://projetouniversitas-evora.oa.r.appspot.com/api/notifications/send";

      const response = await fetch(endpoint,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setSuccessMessage("Notificação enviada com sucesso!");
        // Reset form fields
        setTitle("");
        setBody("");
        setScreen("");
        setUser("");
        setSelectedData(null); // Clear selected news after submission
      } else {
        const errorData = await response.json();
        setErrorMessage(`Erro ao enviar a notificação: ${errorData.message}`);
      }
    } catch (error) {
      setErrorMessage("Ocorreu um erro ao enviar a notificação.");
    } finally {
      setIsSubmitting(false);
    }
  };





// Handle image selection
const onImageSelect = (file) => {
  if (file) {
    setBannerImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setBannerPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }
};

// Confirm and upload the selected image
const handleConfirm = async () => {
  if (bannerImage) {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("name", "banner");
    formData.append("photo", bannerImage);

    try {
      const response = await fetch(
        "https://projetouniversitas-evora.appspot.com/api/menu-images",
        {
          method: "PUT",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        setSuccessMessage("Imagem atualizada com sucesso!");
        fetchFrontendImage(); // Fetch the updated frontend image
        resetImageSelection();
      } else {
        setErrorMessage("Falha ao carregar a imagem.");
      }
    } catch (error) {
      setErrorMessage("Erro ao carregar a imagem.");
    } finally {
      setIsUploading(false);
    }
  }
};

// Reset image selection and preview
const resetImageSelection = () => {
  setBannerImage(null);
  setBannerPreviewUrl(null);
  if (fileInputRef.current) {
    fileInputRef.current.value = null;
  }
};

// Cancel image upload
const handleCancel = () => {
  resetImageSelection(); // Clear the selected image and preview
};













  return (
    <Box width="100%">
      {/* Header */}
      <Header
        title="Gestão da Aplicação móvel"
        subtitle="Cria e gera notificações e imagens da aplicação"
        showPhotoImporter={false}
      />

      {/* Flex container for Notifications and Images */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }} // Column for small screens, row for larger screens
        justifyContent="space-between"
        padding={3}
        gap={3}
      >
        {/* Notificações Section */}
        <Box flex={1} padding={3} boxShadow={3} borderRadius={2}>
          <Typography variant="h5" gutterBottom>
            Notificações
          </Typography>
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            gap={3}
            maxWidth="500px"
            margin="0 auto"
          >
            {/* Title Field */}
            <TextField
              label="Título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />

            {/* Body Field */}
            <TextField
              label="Mensagem"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />

            {/* Notification Type Dropdown */}
            <TextField
              select
              label="Tipo de Notificação"
              value={screen}
              onChange={(e) => setScreen(e.target.value)}
              fullWidth
            >
              {notificationTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Token do Usuário (Opcional)"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              fullWidth
            />

            {/* Data selection when specific types are selected */}
            {(screen === "/news" || screen === "/ae-documents" || screen === "/events") && (
              <Box>
                <Typography variant="h6">Escolha um item</Typography>
                {isFetching ? (
                  <CircularProgress />
                ) : (
                  <List>
                    {data.map((item) => (
                      <ListItem
                        button
                        key={item.id}
                        onClick={() => setSelectedData(item)}
                        selected={selectedData?.id === item.id}
                      >
                        <ListItemText primary={item.Title || item.name} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            )}
            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "A Enviar..." : "Enviar Notificação"}
            </Button>
          </Box>
        </Box>

        {/* Imagens da Aplicação Móvel Section */}




        {/* Banner Management Section */}
      {/* Banner Management Section */}
<Box
  flex={1}
  padding={3}
  boxShadow={3}
  borderRadius={2}
  sx={{
    marginBottom: { xs: "4rem", md: "0" }, // Margin only on small screens
  }}
>
  <Typography variant="h5" gutterBottom>
    Banner da Aplicação
  </Typography>

  {/* Current Banner or Preview */}
  <Box marginBottom={2}>
    <Typography variant="h6" gutterBottom>
      {bannerPreviewUrl ? "Pré-visualização do Novo Banner" : "Banner Atual"}
    </Typography>
    <img
      src={bannerPreviewUrl || frontendImage || ""}
      alt={bannerPreviewUrl ? "Pré-visualização" : "Banner Atual"}
      style={{
        maxWidth: "100%",
        maxHeight: "300px",
        objectFit: "contain",
        borderRadius: "8px",
        border: "1px solid #ddd",
      }}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = ""; // Reset image on error
      }}
    />
  </Box>

  {/* Actions Based on Banner State */}
  {!bannerPreviewUrl ? (
    // Show Upload Button if No Preview Exists
    <Button
      variant="outlined"
      component="label"
      disabled={isUploading}
      sx={{ marginBottom: 2 }}
    >
      {isUploading ? "Carregando..." : "Atualizar Banner"}
      <input
        type="file"
        hidden
        accept="image/*"
        ref={fileInputRef}
        onChange={(e) => onImageSelect(e.target.files[0])}
      />
    </Button>
  ) : (
    // Show Confirmation/Cancel Buttons for Preview
    <Box mt={2} display="flex" gap={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        disabled={isUploading}
      >
        {isUploading ? "Uploading..." : "Confirmar"}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleCancel}
        disabled={isUploading}
      >
        Cancelar
      </Button>
    </Box>
  )}
</Box>




      </Box>







      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationsPage;
