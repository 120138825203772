import React, { useState } from "react";
import { Box, Button, TextField, Snackbar, Alert } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import { tokens } from "../../../theme";

import NotificationToggle from "../../global/ShowNotificationField";





const AddDocumentForm = ({ onClose, refreshDocuments, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [notificationData, setNotificationData] = useState(null);


  const handleChanges = (newFile) => {
    // Check if a file is selected
    if (newFile) {
      // Check if the file is a PDF
      if (!newFile.type.includes("application/pdf")) {
        setSnackbarMessage("Apenas ficheiros PDF são permitidos.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setFile(null);
        return; // Prevent setting the file state
      }

      // Check if the file size exceeds 20MB
      if (newFile.size > 20 * 1024 * 1024) { // 20MB in bytes
        setSnackbarMessage("O tamanho máximo do ficheiro é de 20MB.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setFile(null);
        return; // Prevent setting the file state
      }

      // If validation passes, set the file state
      setFile(newFile);
    }
  };


  // Form submission
  const handleFormSubmit = async (values, setSubmitting) => {

    if (!file) {
      setSnackbarMessage("Por favor, adicione um ficheiro antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Prevent form submission
      return

    }


    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("file", file);
    const url = "https://projetouniversitas-evora.appspot.com/ae/add";

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Success handling
      refreshDocuments();
      showSnackbar("Documento adicionado com sucesso!");

      if (notificationData && notificationData.title && notificationData.body) {
        await sendNotification(notificationData);
      }

      onClose();
    } catch (error) {
      // Error handling
      console.error("Error:", error);
      setSnackbarMessage("Erro ao adicionar documento.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setSubmitting(false);
  };

  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        "https://projetouniversitas-evora.oa.r.appspot.com/api/notifications/send",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbarMessage(`Error sending notification: ${errorData.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending notification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };




  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" gap="20px" flexDirection="column">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                placeholder="Insira o nome do documento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Carregar Ficheiro"
                value={file}
                onChange={handleChanges}
              />
            </Box>


            <NotificationToggle
              defaultTitle="Novo documento adicionado"
              defaultBody="Fica a par do que está a acontecer"
              screen="/ae"
              onSend={(data) => setNotificationData(data)}
            />




            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem",
                  fontWeight: 600,
                  backgroundColor: colors.customTheme.primary,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: colors.customTheme.primary,
                    color: "#fff",
                    opacity: 0.7,
                  },
                }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar documento..." : "Criar Documento"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {/* Snackbar for messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Background color
            color: "#fff", // Text color
            borderRadius: "4px", // Rounded corners
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%", textAlign: "center" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  title: "",
  file: null,
};

export default AddDocumentForm;
