import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import MenuIcon from "@mui/icons-material/Menu"; // Ícone de Menu
import CloseIcon from "@mui/icons-material/Close"; // Ícone de Fechar
import logo from "../../assets/logoAE.png";
import { NotificationAddOutlined, Person2Outlined,  LibraryBooksOutlined } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected, setDrawerOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    setSelected(title); // Atualiza o item selecionado
    if (setDrawerOpen) {
      // Se a função de fechar o Drawer estiver disponível (mobile)
      setDrawerOpen(false); // Fecha o Drawer
    }
  };

  return (
    <Box
      sx={{
        backgroundColor:
          selected === title ? colors.customTheme.darkPrimary : "transparent",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: colors.customTheme.darkPrimary,
        },
        margin: "10px 0",
        listStyle: "none",
        "& .pro-inner-item": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
          padding: "10px 20px",
        },
        "& .pro-icon-wrapper": {
          display: "flex",
          alignItems: "center",
        },
        "& .pro-item-content": {
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <MenuItem
        active={selected === title}
        style={{
          color: colors.customTheme.textColor,
          fontWeight: "bolder",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
        }}
        onClick={handleClick} // Chama handleClick ao clicar no item
        icon={<Box sx={{ color: colors.customTheme.textColor }}>{icon}</Box>}
      >
        <Typography>{title}</Typography>
        <Link
          to={to}
          style={{ textDecoration: "none", color: "inherit", width: "100%" }}
        />
      </MenuItem>
    </Box>
  );
};

const SidebarDesktop = ({ selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:750px)"); // Verifica se é uma tela pequena

  return (
    <Box
      sx={{
        display: isSmallScreen ? "none" : "block", // Oculta a sidebar se for uma tela pequena

        "& .pro-sidebar": {
          background: colors.customTheme.primary,
        },
        "& .pro-sidebar-inner": {
          background: colors.customTheme.primary,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: theme.palette.mode === "dark" ? "#fff" : "",
        },
        "& .pro-menu-item.active": {
          color: colors.customTheme.darkPrimary,
        },
      }}
    >
      <ProSidebar>
        <Menu iconShape="square">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.customTheme.primary,
              zIndex: "10",
            }}
          >
            <img
              src={logo}
              alt="3cketBeyond"
              style={{ width: "80%", height: "auto", marginBottom: "3rem" }}
            />
          </Box>
          <Box padding="0% 5%">
            <Item
              title="Utilizadores"
              to="/users"
              icon={<Person2Outlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Documentos"
              to="/ae"
              icon={<DocumentScannerOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Notícias"
              to="/news"
              icon={<NewspaperOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Serviços"
              to="/services"
              icon={<RoomServiceOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Contactos"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Sebenta"
              to="/bancoApontamentos"
              icon={<LibraryBooksOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Notificações"
              to="/notifications"
              icon={<NotificationAddOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

const SidebarContainer = () => {
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [selected, setSelected] = useState("Notícias");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const pathToTitleMap = {
    "/users": "Utilizadores",
    "/ae": "Documentos",
    "/news": "Notícias",
    "/services": "Serviços",
    "/contacts": "Contactos",
    "/bancoApontamentos" : "Sebenta",
    "/notifications" : "Notificações"
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedTitle = Object.keys(pathToTitleMap).find((path) =>
      currentPath.startsWith(path)
    );
    if (matchedTitle) {
      setSelected(pathToTitleMap[matchedTitle]);
    }
  }, [location.pathname]);

  return <SidebarDesktop selected={selected} setSelected={setSelected} />;
};

export default SidebarContainer;
